@import './../../../../scss/theme-bootstrap';

$gnav-signup-max-width: 380px;

.site-email-signup {
  padding: 25px 0 0;
  max-width: $gnav-signup-max-width;
  margin: auto;
  &:nth-child(2) {
    padding-top: 0;
  }
  &__title {
    text-align: center;
  }
  &__subtitle {
    text-align: center;
    font-style: italic;
    &:nth-child(odd) {
      border-top: 1px solid $color-lighter-gray;
      padding-top: 15px;
      margin-bottom: 10px;
    }
  }
  &__copy {
    text-align: center;
  }
  &__messages {
    text-align: center;
  }
  &__error {
    color: $color-error;
  }
  &__fields {
    @include pie-clearfix;
    width: 100%;
    margin: 0 auto;
    input[type='email'],
    input[type='text'] {
      &.error {
        border: 1px solid $color-error;
      }
    }
  }
  &__field {
    display: block;
    width: 280px;
    margin: auto;
    margin-bottom: 1em;
    padding-bottom: 0;
    .mobile-number:-webkit-autofill {
      // Expose a hook for JavaScript when auto fill is shown.
      // JavaScript can capture 'animationstart' events
      animation-name: onAutoFillStart;
    }
    input {
      padding: 0 10px;
    }
  }
  &--email {
    padding-bottom: 0;
  }
  input[type='email'],
  input[type='text'] {
    @include input-placeholder {
      color: $color-light-gray;
      text-align: center;
      text-transform: capitalize;
    }
  }
  &__gdpr-link {
    color: $color-pink-accent;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: center;
    }
    &:hover > .site-email-signup__gdpr-overlay {
      display: block;
    }
    &--check {
      margin-bottom: 20px;
      .site-email-signup__gdpr-link {
        color: $color-black;
        text-decoration: none;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  &__gdpr-overlay {
    display: none;
    position: absolute;
    color: $color-black;
    border: 1px solid $color-pink-accent;
    text-decoration: none;
    padding: 15px;
    background-color: $color-white;
    bottom: 20px;
    p {
      margin: 0;
    }
  }
  &__terms {
    display: none;
    padding: 10px 0 15px;
    position: relative;
    &-checkbox {
      span {
        display: inline-block;
        float: left;
        margin-left: 20px;
        margin-top: -15px;
        text-align: justify;
      }
    }
    &-more {
      margin: 15px 0 0;
      text-align: center;
    }
    &-checkbox,
    &-more {
      font-size: 13px;
      margin-bottom: 20px;
      a {
        color: $color-pink-accent;
      }
    }
    &--error {
      color: $color-error;
    }
  }
  &__submit {
    margin: auto;
    margin-bottom: 38px;
    min-width: 280px;
  }
  &__success {
    color: $color-pink-accent;
    padding: 2em 0;
    position: relative;
    text-align: center;
    &-header {
      text-transform: uppercase;
    }
    &-offer {
      line-height: 1.5em;
      &__code {
        text-transform: uppercase;
      }
    }
    &-terms {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    &-cta {
      margin-bottom: 2em;
      .button--medium {
        min-width: 172px;
      }
    }
  }
}
